import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, SimpleChanges, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import {
  BackgroundAnimations,
  inOutAnimation,
  openCloseTransition,
  routerTransition,
} from 'src/app/animations';
import { Activity, User } from 'src/app/models';
import { Invite } from 'src/app/models/invite';
import { InviteService } from 'src/app/services/invite.service';
import { UtilsService } from '../../services/utils.service';
import { GeoLocation } from 'src/app/models/location';
import { map, startWith } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BillingService } from 'src/app/services/billing.service';
import { StripeService } from 'ngx-stripe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-invite',
  templateUrl: './create-invite.component.html',
  styleUrls: ['./create-invite.component.scss'],
  animations: [
    routerTransition,
    inOutAnimation,
    openCloseTransition,
    BackgroundAnimations,
  ],
})
export class CreateInviteComponent implements OnInit, OnDestroy {
  @Input() profile: any;
  @Input() activity: any;
  @Input() updateLocation: GeoLocation;
  @Output() close: EventEmitter<any>;

  activities: Activity[];
  user: User;
  invite: Invite;
  subs: Subscription[] = [];
  form: FormGroup;
  submitted = false;
  title: string = 'Create';
  btnTitle: string = 'Invite';
  icon: string = 'add';
  flags = {
    step: 0,
    sfe: false, // Show Form Errors
    loading: false,
    btnSubmit: true,
  };
  prices: any = ['Free', 5, 10, 15, 20, 25, 30, 35, 40, 42, 45, 50, 60, 70, 80, 88, 90, 100];
  durations: any = [
    { name: '15 Mins', value: '00:10:00' },
    { name: '30 Mins', value: '00:30:00' },
    { name: '45 Mins', value: '00:45:00' },
    { name: '1 Hour', value: '01:00:00' },
    { name: '75 Mins', value: '01:15:00' },
    { name: '1.5 Hours', value: '01:30:00' },
    { name: '2 Hours', value: '02:00:00' },
    { name: '2.5 Hour', value: '02:30:00' },
    { name: '3 Hour', value: '03:00:00' },
    { name: '3.5 Hour', value: '03:30:00' },
    { name: '4 Hour', value: '04:00:00' },
    { name: '4.5 Hour', value: '04:30:00' },
    { name: '5+ Hours', value: '05:00:00' },
  ];
  times: string[];
  minDate: Date;
  maxDate: Date;
  startDate: Date;
  // location: string;
  // geoLocation: GeoLocation;
  locationDisplay: string;
  location: GeoLocation;
  locationCtrl = new FormControl();
  filteredLocations: Observable<GeoLocation[]>;
  allLocations: GeoLocation[] = [];
  suggestedLocations: GeoLocation[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public utils: UtilsService,
    private renderer: Renderer2,
    private inviteService: InviteService,
    private locationService: LocationService,
    private billingService: BillingService,
    private stripeService: StripeService,
  ) {
    this.close = new EventEmitter();

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
    this.subs.push(user);

    this.times = this.createTimes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.updateLocation.currentValue != null){
      // this.initLocation();
      if(this.invite != null){
        // this.invite.activity = this.activity;
      }
      if(this.invite != null && this.user.app_state.setActivityLocation != null){
        // this.user.app_state.showSetLocation
        this.setLocation(this.user.app_state.setActivityLocation);
      }
    }
  }

  ngOnInit() {
    // this.initLocation();

    if (this.profile.activities != null) {
      // TODO remove dups/incommon and order by alpha
      this.profile.activities = _.sortBy(this.profile.activities, ['activity.name']);
      // this.activities = this.profile.activities.concat(this.user.activities);
    }

    // if (this.user.location != null && this.user.location.city != null) {
    //   this.locationDisplay = this.user.location.city + ', ' + this.user.location.state;
    //   this.location = this.user.location;
    // }

    this.flags.step = 0;
    if(this.activity != ""){
      this.flags.step = 1;
    }

    this.setupForm();
  }

  setLocation(location) {
    if(location.place != null){
      this.invite.location = location;
      this.locationDisplay = location.name;
      this.stepForward();
    }
  }  

  initLocation() {
    //SET DEFAULT LOCATION AS AREA
    if (this.user.location != null && this.user.location.city != null) {
      this.locationDisplay = this.user.location.city + ', ' + this.user.location.state;
      this.location = this.user.location;
    }
    //SET DEFAULT LOCATION AS PLACE / BUSINESS
    else if (this.user.location != null && this.user.location.city != null && this.user.location.place == null) {
      this.location.place = this.locationDisplay;
    }

    if (this.invite && this.user.app_state.setActivityLocation != null) {
      this.location = this.user.app_state.setActivityLocation;
      this.locationDisplay = this.user.app_state.setActivityLocation.place;
      this.f.location.setValue(this.location);
      this.f.locationDisplay.setValue(this.locationDisplay);
    }

    if(this.activity != null && this.activity.activity != null && this.activity.activity.name != null){
      this.getLocationSuggestions(this.user.location.location.coordinates, this.activity.activity.name);
    }
  }

  emitClose() {
    this.close.emit(true);
  }

  setupForm() {
    let userPrice = 'Free';
    if(this.activity.price != null){
      userPrice = this.activity.price
    }

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const inOneMonth = new Date();
    this.minDate = new Date(new Date());
    this.maxDate = new Date(inOneMonth.setMonth(inOneMonth.getMonth() + 3));
    this.startDate = new Date(tomorrow);
    if(this.activity.duration == null){
      if(this.activity == null || this.activity == ""){
        this.activity = {};
      }
      this.activity['duration'] = '01:00:00';
    }

    this.invite = new Invite({
      activity: this.activity.activity,
      date: tomorrow,
      time: '10:00AM',
      duration: this.activity.duration,
      price: userPrice,
      // location: this.location,
      location: null,
      description: '',
    });
    this.setNearestTime();
    this.form = this.formBuilder.group({
      activity: [this.invite.activity, Validators.required],
      date: [tomorrow, Validators.required],
      time: [this.invite.time, Validators.required],
      duration: [this.invite.duration, Validators.required],
      locationDisplay: [null],
      location: [null],
      description: [this.invite.comment],
      private: [false],
      creator: [this.user.id],
      datetime: [''],
      image: [''],
    });

    if (this.f.activity.value != 0) {
      this.activateBtn();
    }
  }

  youHaveActivity(activity) {
    if (activity != null) {
      let activities = _.filter(this.user.activities, item => item.activity.id == activity.id);
      if (activities.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  matchDuration() {
    console.log(this.invite.duration);
    let duration = moment.duration(this.invite.duration).asMinutes();
    console.log(duration);
    let durationstring = duration.toString().replace('0.', '');
    console.log(durationstring);

    // this.post.duration = parseInt(durationstring);
  }

  setNearestTime() {
    let nearest = this.times
      .map(function (s) {
        return moment(s, 'h:mma');
      })
      .sort(function (m) {
        return m.valueOf();
      })
      .find(function (m) {
        return m.isAfter();
      });

    if (nearest) {
      this.invite.time = nearest.format('h:mma');
      // console.log("Next time is", nearest.format("hh:mma"), "which is", nearest.fromNow());
    } else {
      this.invite.time = '10:00am';
    }
  }

  matchTimes() {
    this.times.forEach((time) => {
      let posttime = moment(this.invite.datetime, 'h:mma');
      let dropdowntime = moment(time, 'h:mma');

      if (posttime.format('h:mma') == dropdowntime.format('h:mma')) {
        this.invite.time = time;
      }
    });
  }

  createTimes() {
    var x = 15; //minutes interval
    var times = []; // time array
    var tt = 240; // start time
    var ap = ['AM', 'PM']; // AM-PM

    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = tt % 60; // getting minutes of the hour in 0-55 format
      times.push(moment(hh + ':' + mm, 'h:mm').format('h:mma'));
      // times[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    }
    return times;
  }

  get f() {
    return this.form.controls;
  }

  clickClose(){
    if(this.form.touched){
      if(!confirm('Cancel Invite Request')){
        return;
      }
      this.user.app_state.setActivityLocation = null;
      this.close.emit(true);
    }
    if(this.form.untouched){
      this.user.app_state.setActivityLocation = null;
      this.close.emit(true);
    }
  }

  roundPrice(price){
    if(!isNaN(price)){
      return Math.floor(Number(price));
    }
    else{
      return 'Free';
    }
  }

  disableBtn(){
    if((this.f.activity.value == null || this.f.activity.value == '') || (this.flags.step == 3 && this.f.location.value == null)){
      return true;
    }
    return false;
  }

  priceChange(value, activity){
    this.utils.sound('next');
    this.invite.price = value;
  }

  timeChange(value){
    this.utils.sound('next');

    let datetime = moment(
      this.f.date.value.toISOString().split('T')[0] + ' ' + this.f.time.value,
      'YYYY-MM-DD HH:mm A'
    );

    this.activity.datetime = datetime;
  }

  activityChange(activity){
    this.activity = activity;
    this.invite.activity = activity;
    this.flags.step = 1;
    if(this.activity != null && this.activity.name != null){
      this.getLocationSuggestions(this.user.location.location.coordinates, this.activity.name);
    }
  }

  stepForward(){
    this.flags.step++;
    if(this.flags.step == 5){
      this.onSubmit();
    }
  }
  
  @ViewChild('btnForward') btnForward; 
  stepBack(){
    this.renderer.removeClass(this.btnForward._elementRef.nativeElement, 'btnReady2');
    this.flags.step--;
  }
  
  // DATA
  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      this.flags.sfe = true;
      return;
    }

    let datetime = moment(
      this.f.date.value.toISOString().split('T')[0] + ' ' + this.f.time.value,
      'YYYY-MM-DD HH:mm A'
    );

    this.utils.sound('send');
    this.flags.btnSubmit = true;

    let status = 'self-pay-pending';
    if(this.invite.price == 'Free'){
      this.invite.price = '0';
      status = 'Pending';
    }

    let data = {
      status: status,
      location: this.invite.location,
      price: this.invite.price,
      duration: this.f.duration.value,
      comment: this.f.description.value,
      ifrom: this.user.id,
      to: this.profile.id,
      activity: this.activity.activity.id,
      datetime: datetime,
    };
    this.inviteService.create(data).subscribe(
      (data) => {
        //  this.invite = data;
        if(this.invite.price == '0'){
          this.utils.alert.success(this.activity.activity.name + ' request sent...');
        }
        else{
          this.payInvite(data['id']);
        }

        this.user.app_state.setActivityLocation = null;
        this.close.emit(true);
      },
      (error) => {
        this.utils.showServerErrorsInForm(error, this.form);
        this.flags.btnSubmit = false;
      }
    );
  }

  payInvite(inviteid) {
    const stripe: any = this.stripeService.getInstance();
    let returnUrl = environment.appUrl + 'dashboard';

    this.billingService.payInvite(inviteid, {success_url: returnUrl + '/invite-paid', cancel_url: returnUrl}).subscribe(data => {
      stripe.redirectToCheckout({sessionId: data.session_id}).then(function (result) {
        this.utils.alert.success("Some didn't work.");
        console.log(result);
      });

    });
  
  
  }

  // NEW LOCATION SPECIFIC PLACE
  getCitiesByName(name) {
    this.locationService.getCities(name, 15).subscribe((data) => {
      this.allLocations = data.results;
    });
  }

  getLocationsByName(coordinates, searchStr) {
    this.locationService.getNearBy(coordinates, searchStr, 10).subscribe((data) => {
      this.allLocations = data;
    });
  }

  getLocationSuggestions(coordinates, searchStr?) {
    this.locationService.getNearBy(coordinates, searchStr, 30).subscribe((data) => {
      this.suggestedLocations = data;
    });
  }


  activateBtn() {
    if (!this.form.invalid) {
      this.flags.btnSubmit = false;
    } else {
      this.flags.btnSubmit = true;
    }
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
