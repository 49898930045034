import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule, MAT_CHECKBOX_CLICK_ACTION } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlickityModule } from 'ngx-flickity';

// import 'hammerjs';
// import { BsDropdownModule, ModalModule, TooltipModule } from 'ngx-bootstrap';
import {
  DateToAge,
  DaysToNow,
  DayGroups,
  isInvited,
  isLightColor,
  trimDots,
  isLiked,
  StringToDisplayTime,
  TimeAgo,
  DateToDayofMonth,
  SafePipe,
  LinkifyPipe,
} from '../directives/pipe.component';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { MatAnimatedIconComponent } from '../components/mat-animated-icon/mat-animated-icon.component';

// import {ScrollSpyModule, ScrollSpyAffixModule, ScrollSpyIndexModule} from "ngx-scrollspy";
// import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
// import { MomentModule } from 'angular2-moment';

@NgModule({
  imports: [CommonModule],
  exports: [
    // BrowserAnimationsModule,
    //  ScrollSpyModule, ScrollSpyIndexModule, ScrollSpyAffixModule,
    // MomentModule, ScrollToModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatCardModule,
    MatDialogModule,
    MatProgressBarModule,
    MatMenuModule,
    MatTableModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatSortModule,
    MatListModule,
    MatTabsModule,
    MatTooltipModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatChipsModule,
    DeviceDetectorModule,
    FlickityModule,
    
    //PIPES
    TimeAgo,
    DaysToNow,
    DateToAge,
    DateToDayofMonth,
    isLightColor,
    trimDots,
    DayGroups,
    isLiked,
    isInvited,
    StringToDisplayTime,
    NgAddToCalendarModule,
    SafePipe,
    LinkifyPipe,
    //  Can we remove  ??
    //     BsDropdownModule, ModalModule, TooltipModule,
  ],
  declarations: [
    TimeAgo,
    DateToAge,
    DaysToNow,
    DateToDayofMonth,
    isLightColor,
    trimDots,
    DayGroups,
    isLiked,
    isInvited,
    StringToDisplayTime,
    SafePipe,
    LinkifyPipe,
  ],
  providers: [
    DayGroups, isLiked, isInvited, SafePipe,
  ],
})
export class UtilsModule { }
