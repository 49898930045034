<div class="items animate__animated" *ngIf="user.app_state.showSetExperience != 'out'" [class.animate__backInRight]="flags.animation=='start'" [class.animate__backOutLeft]="flags.animation=='end'">
<!-- <div class="items animate__animated animate__backInRight" *ngIf="activity != null"> -->
  <div class="height100 vcenter">
    <button mat-button class="closeBtn" [class.iosPadding]="utils.ios()" (click)="clickDone()" *ngIf="user?.color != null"><mat-icon>close</mat-icon></button>
    <div class="item">
      <div class="question">
        <mat-icon>signal_cellular_alt</mat-icon><br />
        {{activity?.activity?.name}} experience
      </div>
      <br />
      <br />
    </div>
    <div class="item experience">
      <div (click)="set(1)" class="animate__animated" [class.animate__flip]="activity?.experience == 1">
        <mat-icon *ngIf="activity?.experience >= 1" [style.color]="user?.color" [class.on]="activity?.experience == 1">star</mat-icon>
        <mat-icon *ngIf="activity?.experience == 0">star_border</mat-icon>
        <p>Noob</p>
      </div>
      <div (click)="set(2)" class="animate__animated" [class.animate__flip]="activity?.experience == 2">
        <mat-icon *ngIf="activity?.experience >= 2" [style.color]="user?.color" [class.on]="activity?.experience == 2">star</mat-icon>
        <mat-icon *ngIf="activity?.experience <= 1">star_border</mat-icon>
        <p>Good</p>
      </div>
      <div (click)="set(3)" class="animate__animated" [class.animate__flip]="activity?.experience == 3">
        <mat-icon *ngIf="activity?.experience >= 3" [style.color]="user?.color" [class.on]="activity?.experience == 3">star</mat-icon>
        <mat-icon *ngIf="activity?.experience <= 2">star_border</mat-icon>
        <p>Legend</p>
      </div>
      <div (click)="set(4)" class="animate__animated" [class.animate__flip]="activity?.experience == 4">
        <mat-icon *ngIf="activity?.experience >= 4" [style.color]="user?.color" [class.on]="activity?.experience == 4">star</mat-icon>
        <mat-icon *ngIf="activity?.experience <= 3">star_border</mat-icon>
        <p>Guide</p>
      </div>
      <br />
      <br />
    </div>
    <div class="item checkboxes">
      <mat-checkbox [checked]="activity?.will_teach" (change)="activity.will_teach=!activity.will_teach;activateDone()"><i>Willing to Teach</i></mat-checkbox>
      <mat-icon class="certIcon" *ngIf="activity?.will_teach">school</mat-icon>
    </div>
    <div class="item checkboxes">
      <mat-checkbox [checked]="activity?.has_certification" (change)="activity.has_certification=!activity.has_certification;activateDone()"><i>Certified Teacher</i></mat-checkbox>
      <mat-icon class="certIcon" *ngIf="activity?.has_certification">badge</mat-icon>
    </div>
    <br />
    <button [disabled]="!flags?.btnDone" [class.btnReady2]="!flags?.btnDone" mat-flat-button color="primary" (click)="clickDone()" class="item height10">
      <mat-icon>arrow_forward</mat-icon>
    </button>
    </div>
</div>