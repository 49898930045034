<!-- <div fxLayout="row" fxLayoutAlign="center center" fxFlex class="createPostComponent" [@slideInOut]=user?.app_state?.createPostBox> -->
  <mat-progress-bar mode="indeterminate" class="loginProgress" *ngIf="flags.loading" [class.iosProgress]="utils.ios()"></mat-progress-bar>

  <div class="items height100 vcenter animate__animated" [class.animate__zoomInUp]="!submitted"  [class.animate__backOutUp]="submitted">
    <button mat-button class="closeBtn" [class.iosPadding]="utils.ios()" (click)="clickClose()" *ngIf="user?.color != null"><mat-icon>close</mat-icon></button>

      <div fxLayout="row" fxLayoutGap="20px" class="item progress">
        <div fxFlex>
          <button mat-button type="button" (click)="flags.step=0" class="pill" [style.background]="'linear-gradient(184deg,'+user?.color+'d4,'+user?.color+'70)'" *ngIf="flags?.step != 0">{{invite?.activity?.name}}</button>
          <button mat-button type="button" (click)="flags.step=1" class="pill" [style.background]="'linear-gradient(184deg,'+user?.color+'d4,'+user?.color+'70)'" *ngIf="flags?.step > 1">{{invite?.duration|displayTime }} for {{utils.displayPrice(activity?.price)}}</button>
          <button mat-button type="button" (click)="flags.step=2" class="pill" [style.background]="'linear-gradient(184deg,'+user?.color+'d4,'+user?.color+'70)'" *ngIf="flags?.step > 2">{{f.date.value|date:'MM/dd'}} @ {{this.f.time.value}}</button>
          
          <button mat-button type="button" (click)="flags.step=4" class="pill" [style.background]="'linear-gradient(184deg,'+user?.color+'d4,'+user?.color+'70)'" *ngIf="flags?.step > 3 && f?.location?.value?.name != null">{{f?.location?.value?.name}}<span *ngIf="f?.location.value.name && f?.location?.place?.properties?.street != null">, </span>{{f?.location?.place?.properties?.street}}<span *ngIf="f?.location?.place?.properties?.street && f?.location?.place?.properties?.stateCode != null">, </span>{{f?.location?.place?.properties?.stateCode}}</button>
          <button mat-button type="button" (click)="flags.step=3" class="pill" [style.background]="'linear-gradient(184deg,'+user?.color+'d4,'+user?.color+'70)'" *ngIf="flags?.step > 3 && invite?.location?.name == null && invite?.location?.place != ''">{{invite?.location?.place}}</button>
        </div>
      </div>

      <button type="button" mat-flat-button color="primary" class="item" (click)="stepBack()" *ngIf="flags?.step != 0">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <br />

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
  

      <div class="start item animate__animated animate__fadeIn animate__slideInDown" *ngIf="flags?.step == 0">
        <div fxLayout="row" fxLayoutGap="20px" [class.ios]="utils.ios()">
          <div fxFlex>
            <div class="question">
              <mat-icon>perm_contact_calendar</mat-icon><br />
            </div>
              <h2>Request  {{profile.first_name}}</h2>
            <p class="text-center">on a fun play Date!</p>
          </div>
        </div>   

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex>
            <mat-form-field>
              <mat-select placeholder="Choose the Activity" (selectionChange)="activityChange($event.value)" formControlName="activity" [value]="activity">
                <mat-option *ngFor="let activities of profile.activities" [value]="activities['activity']">
                  {{activities['activity'].name}}
                </mat-option>
                <mat-optgroup label="Your Activities" *ngIf="user?.is_verified">
                  <mat-option *ngFor="let activities of user.activities" [value]="activities['activity']">
                    {{activities['activity'].name}}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>


      <div class="item when animate__animated animate__fadeIn animate__slideInDown" *ngIf="flags?.step == 1">
        <h2>Price & Duration</h2>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="60">
            <mat-form-field>
              <span matPrefix>$ &nbsp;</span>
              <!-- CAN"T GET SELECTED VALUE TO SELECT CURRENT VALUE "Free" -->
                <mat-select placeholder="Price" (selectionChange)="priceChange($event.value, activity)" [value]="roundPrice(invite?.price)">
                <mat-option *ngFor="let price of prices" [value]="price">
                  {{price}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="35">
            <mat-form-field>
              <mat-select placeholder="Duration" formControlName="duration">
                <mat-option *ngFor="let duration of durations" [value]="duration.value">
                  {{duration.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <div>
            <p class="text-center">Payment is held until both parties complete the meet up successfully.</p>
          </div>
        </div>
      </div>

      <div class="item when animate__animated animate__fadeIn animate__slideInDown" *ngIf="flags?.step == 2">
        <h2>When</h2>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="50">
            <mat-form-field>
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" (keyup)="activateBtn()" (dateChange)="activateBtn()" type="text" formControlName="date" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
              <mat-error *ngIf="f.date.hasError('serverError')">
                <div>{{f.date.errors.serverError}}</div>
              </mat-error>
              <mat-error *ngIf="f.date.hasError('birthday')">
                <div>Invalid entry.</div>
              </mat-error>
              <mat-error *ngIf="f.date.hasError('required')">
                <div>Required field.</div>
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <mat-form-field>
              <mat-select placeholder="Time" formControlName="time" (change)="timeChange($event.value)">
                <mat-option *ngFor="let time of times" [value]="time">
                  {{time}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row"  class="item animate__animated animate__fadeIn animate__slideInDown" *ngIf="flags?.step == 3">
        <div fxFlex>
          <h2>Where</h2>
          <app-location-place (change)="setLocation($event)" [inputLocation]="location" [nearby]="f?.activity.value.name"></app-location-place>
        </div>
      </div>           

      <div class="item when animate__animated animate__fadeIn animate__slideInDown" *ngIf="flags?.step == 4">
        <h2>Description</h2>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex>
            <br />
            <mat-form-field>
              <!-- <input matInput placeholder="Description" type="text" class="form-control" formControlName="description" [ngClass]="{ 'is-invalid': flags.sfe && f.description.invalid }"> -->
              <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
              placeholder="Message / Extra Info"  class="form-control" formControlName="description" [ngClass]="{ 'is-invalid': flags.sfe && f.description.invalid }"></textarea>
              <mat-error *ngIf="f.description.hasError('serverError')">
                <small>{{f.description.errors.serverError}}</small>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

    </form>

    <br />
    <button mat-flat-button color="primary" #btnForward class="item" (click)="stepForward()" [class.btnReady2]="!disableBtn()" [disabled]="disableBtn()">
      <mat-icon>arrow_forward</mat-icon>
    </button>
    


  </div>

