<mat-progress-bar mode="indeterminate" class="loginProgress" *ngIf="flags.loading" [class.iosProgress]="utils.ios()"></mat-progress-bar>
  <!-- QUESTION ACTIVITIES -->
  <app-add-activities (done)="activitiesDone()" [mode]="user?.app_state?.showSetActivities" *ngIf="displayItem() == 'activities'"></app-add-activities>

  <app-activity-days (change)="daysChanged($event.value)" [activity]="activity" *ngIf="flags?.showSetDays" [class.showIn]="user?.app_state?.showSetDays == 'in'" [class.showOut]="user?.app_state?.showSetDays == 'out'"></app-activity-days>
  <!-- <app-create-post (posting)="creatingPost($event)" [hideActivityOptions]="flags.hideActivityOptions" [title]="text.postTitle" [subTitle]="text.postSubTitle" [updateLocation]="updatePostLocation" class="createPost" *ngIf="user.app_state['createPostBox'] != 'outLeft'"></app-create-post> -->

<div  class="backgroundCoverFull backgroundLeft">
  <div fxlayout="column" fxLayoutAlign="start start" class="homeComponent" >
    <!-- [@fadeBackground]=user?.app_state?.homeCover> -->
        <!-- QUESTION COLOR -->
        <app-color-picker (change)="clickColor($event)" *ngIf="displayItem() == 'colorpicker'"></app-color-picker>

    <div class="homeContent vcenter">

      <button mat-button class="btnBack" (click)="back();utils.sound('home')" *ngIf="user?.color != null">
        <mat-animated-icon start="arrow_back" end="arrow_back" colorStart="none" colorEnd="none"></mat-animated-icon>
      </button>
   

      <div class="items">
        <!-- WELCOME -->
        <!-- <div class="welcome animate__animated animate__fadeIn animate__jackInTheBox" *ngIf="flags?.displayItem == 'welcome'">
            <div class="item">
              <div class="question">
                <mat-icon>favorite</mat-icon>
                <mat-icon class="zoomBoom one">favorite</mat-icon>
                <mat-icon class="zoomBoom two">favorite</mat-icon>
                <mat-icon class="zoomBoom three">favorite</mat-icon>
                <mat-icon class="zoomBoom four">favorite</mat-icon>
              <br />
              YAY u did something!
              </div>
              <br />
            </div>
            <div class="item">
                <p>Please have FUN, be KIND and enjoy our creation.</p>
                <br />
                <p>May your life's journey be an EPIC adventure!</p>
                <br />
            </div>
            <a mat-button color="primary" class="item" (click)="showWelcome=false;utils.sound('next')">RIDE ON <mat-icon>arrow_right</mat-icon></a>      
        </div>  -->


        <!-- QUESTION SINGLE -->
        <div class="items gender animate__animated animate__fadeIn" *ngIf="displayItem() == 'single'">
          <mat-radio-group aria-label="Dude">
            <div class="item">
              <div class="question">
              <mat-icon *ngIf="user?.is_single" [style.background]="user?.color">person</mat-icon><br />
              <mat-icon *ngIf="!user?.is_single" [style.background]="user?.color">group</mat-icon><br />
              I'm currently...
              </div>
            </div>
            <div class="item option animate__animated animate__bounceInDown" (click)="delayAction($event, 'single', 'animate__flip')">
                <h2>Single</h2>
                <p>It's time for a REAL date, a play date!</p>
            </div>
            <div class="item option alt animate__animated animate__bounceInDown" (click)="delayAction($event, 'notsingle', 'animate__flip')">
                <h2>In a Relationship</h2>
                <p>Even more reason u's should get out of your routine and setup play dates!</p>
            </div>
          </mat-radio-group>
        </div> 

        <!-- QUESTION GENDER -->
        <div class="items gender animate__animated animate__fadeIn" *ngIf="displayItem() == 'gender'">
          <div class="item">
            <div class="question">
            <mat-icon>near_me</mat-icon><br />
            Today I'm mostly...
            </div>
            <br />
          </div>

          <div class="item" [class.on]="user?.orientation == 'S'" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'S', 'animate__jackInTheBox')">
            <mat-icon class="largeIcon">straight</mat-icon>
            <p>straight</p>
          </div>
          <div class="item" [class.on]="user?.orientation == 'G'" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'G', 'animate__jackInTheBox')">
            <mat-icon class="largeIcon">u_turn_right</mat-icon>
            <p>gay</p>
          </div>
          <div class="item" [class.on]="user?.orientation == 'B'" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'B', 'animate__jackInTheBox')">
            <mat-icon class="largeIcon">merge</mat-icon>
            <p>bi</p>
          </div>

          <div class="hr"></div>
          <br clear="all" />

          <div class="item" [class.on]="user?.gender == 'M'" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'M', 'animate__zoomInDown')">
          <!-- (click)="clickGender('M', user?.orientation)"> -->
            <mat-icon class="largeIcon">man</mat-icon>
            <p>Dude</p>
          </div>
          <div class="item" [class.on]="user?.gender == 'F'" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'F', 'animate__zoomInDown')">
            <mat-icon class="largeIcon">woman</mat-icon>
            <p>Chicka</p>
          </div>
          <div class="item" [class.on]="user?.orientation == 'T'" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'T', 'animate__zoomInDown')">
            <mat-icon class="largeIcon">sync</mat-icon>
            <p>Trans</p>
          </div>

        </div> 
                
        <!-- QUESTION PARTY -->
        <div class="items party animate__animated animate__fadeIn" *ngIf="displayItem() == 'party'">
          <div class="item">
            <div class="question">
            <mat-icon>celebration</mat-icon><br />
            and I enjoy...
            </div>
            <br />
          </div>

          <div class="item" [class.on]="!user?.is_drinker && user?.is_drinker != null" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'drink_false', 'animate__zoomInDown')">
            <mat-icon class="largeIcon">no_drinks</mat-icon>
            <p>None</p>
          </div>
          <div class="item" [class.on]="user?.is_drinker_beer" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'drink_beer', 'animate__jackInTheBox')">
            <mat-icon class="largeIcon">sports_bar</mat-icon>
            <p>Beer</p>
          </div>
          <div class="item" [class.on]="user?.is_drinker_wine" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'drink_wine', 'animate__zoomInDown')">
            <mat-icon class="largeIcon">wine_bar</mat-icon>
            <p>Wine</p>
          </div>
          <div class="item" [class.on]="user?.is_drinker_spirits" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'drink_spirits', 'animate__jackInTheBox')">
            <mat-icon class="largeIcon">local_bar</mat-icon>
            <p>Spirits</p>
          </div>

          <div class="hr"></div>
          <br clear="all" />

          <div class="item" [class.on]="!user?.is_smoker && user?.is_smoker != null" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'smoke_false', 'animate__zoomInDown')">
            <mat-icon class="largeIcon">smoke_free</mat-icon>
            <p>None</p>
          </div>
          <div class="item" [class.on]="user?.is_smoker_cig" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'smoke_true', 'animate__zoomInDown')">
            <mat-icon class="largeIcon">smoking_rooms</mat-icon>
            <p>Smoke</p>
          </div>
          <div class="item" [class.on]="user?.is_smoker_vape" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'smoke_vape', 'animate__jackInTheBox')">
            <mat-icon class="largeIcon">vaping_rooms</mat-icon>
            <p>Vape</p>
          </div>
          <div class="item" [class.on]="user?.is_smoker_weed" class="item option2 animate__animated animate__pulse" (click)="delayAction($event, 'smoke_weed', 'animate__jackInTheBox')">
            <mat-icon class="largeIcon">spa</mat-icon>
            <p>Weed</p>
          </div>
          <button [disabled]="!flags?.btnDoneParty" [class.btnReady]="flags?.btnDoneParty" mat-flat-button color="primary" (click)="clickDoneParty()" class="item height10">
            <mat-icon>arrow_forward</mat-icon>
          </button>

        </div> 
  
    </div>

  </div>
</div>











