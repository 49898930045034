import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  constructor(private http: HttpClient, private utils: UtilsService) {}

  initPush() {
    if (Capacitor.isNativePlatform()) {
      this.registerPush();
    }
  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {

      let data = {
        registration_id: token.value,
        cloud_message_type: "FCM"
      };
      const url = this.utils.getUrl('rest:device-fcm-list');
      console.log('Register my token for app: ' + token.value, url, data);
      return this.http.post(url, data).subscribe(resp=>{
        console.log("Device Registered", resp);
      }, err=>{
        console.log("Failed to Register device", err);
      });
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        console.log(
          'Action performed: ' + JSON.stringify(notification.notification)
        );
      }
    );
  }
}
