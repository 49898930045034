import { Pipe, PipeTransform } from '@angular/core';
// import * as numeral from 'numeral';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    if (!url.includes('https://') && !url.includes('http://')){
      return 'https://' + this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
} 

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any, args?: any): any {
    if(value == '' || value == null){
      return value;
    }
    return this.sanitizer.bypassSecurityTrustHtml(this.addhref(value));
  }

  private addhref(text: string): string {
    // let urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
    let urlRegex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
    
    return text.toString().replace(urlRegex, function(url) {
        let link = url;
        let warning = ' onclick="return confirm(\'Open external link? Are you sure?\')"';
        if (!url.includes('https://') && !url.includes('@')){
          link = 'https://' + url;
        }
        else if (!url.includes('https://') && url.includes('@')){
          link = 'mailto:' + url;
        }
        if(link.startsWith("https://activity8.com") || link.startsWith("https://www.activity8.com") || link.startsWith("https://activity8.app")){
          warning = '';
        }
        return '<a href="' + link + '" target="_blank"'+ warning +'>' + url + '</a>';
     });
  }
}

@Pipe({ name: 'isInvited' })
export class isInvited implements PipeTransform {
  transform(id: number, invites: any, contentType: string): any {
    let resp: number = 0;
    if (id != null) {
      _.filter(invites, (invite) => {
        if (invite.post && invite.post.id == id) {
          resp = invite;
        }
      });
    }
    return resp;
  }
}

@Pipe({ name: 'isLiked' })
export class isLiked implements PipeTransform {
  transform(id: number, likes: any, contentType: string): number {
    let resp: number = 0;
    if (id != null) {
      _.filter(likes, (like) => {
        if (like.content_id == id) {
          resp = like.id;
        }
      });
    }
    return resp;
  }
}

@Pipe({ name: 'trimDots' })
export class trimDots implements PipeTransform {
  transform(text: string, length: number): string {
    if(text.length > length){
      text = text.slice(0, length) + '...';
    }  
    return text;
  }
}

@Pipe({ name: 'isLight' })
export class isLightColor implements PipeTransform {
  transform(color: string): boolean {
    if (color != null) {
      color = color.substring(1); // strip #
      var rgb = parseInt(color, 16); // convert rrggbb to decimal
      var r = (rgb >> 16) & 0xff; // extract red
      var g = (rgb >> 8) & 0xff; // extract green
      var b = (rgb >> 0) & 0xff; // extract blue

      var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

      if (luma > 125) {
        return true;
      } else {
        return false;
      }
    }
  }
}

@Pipe({ name: 'dayGroups' })
export class DayGroups implements PipeTransform {
  transform(days: string): string {
    let weekends: boolean = days.includes('Sa') && days.includes('Su');
    let hasWeekends: boolean = days.includes('Sa') || days.includes('Su');
    let weekdays: boolean =
      days.includes('Mo') &&
      days.includes('Tu') &&
      days.includes('We') &&
      days.includes('Th') &&
      days.includes('Fr');
    let hasWeekdays: boolean =
      days.includes('Mo') ||
      days.includes('Tu') ||
      days.includes('We') ||
      days.includes('Th') ||
      days.includes('Fr');

    let daySumary;
    if (weekends && weekdays) {
      return 'Everyday';
    } else if (weekends && !hasWeekdays) {
      daySumary = 'Weekends';
    } else if (weekdays && !hasWeekends) {
      daySumary = 'Weekdays';
    } else if (weekdays && hasWeekends) {
      let day = days.includes('Sa') ? 'Sa' : 'Su';
      daySumary = 'Weekdays, ' + day;
    } else {
      daySumary = days;
    }

    return daySumary;
  }
}

@Pipe({ name: 'age' })
export class DateToAge implements PipeTransform {
  transform(value: Date): string {
    let today = new Date();
    let birthDate = new Date(value);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }
}

@Pipe({ name: 'displayTime' })
export class StringToDisplayTime implements PipeTransform {
  transform(value: string): string {
    if (value != null) {
      let p = value.split(':');
      if (p.length > 1) {
        if (p[0] == '00') {
          return p[1] + 'mins';
        } else if (p[0] != '00') {
          let hours = parseInt(p[0]);
          let hourName = 'hour';
          if (hours > 1) {
            hourName = 'hours';
          }

          if (p[1] == '00') {
            return hours + hourName;
          } else {
            return hours + hourName + ' ' + parseInt(p[1]) + 'mins';
          }
        }
      } else {
        return value;
      }
    }
  }
}

@Pipe({ name: 'timeAgo' })
export class TimeAgo implements PipeTransform {
  transform(dateString: string, showSeconds?: boolean): string {
    if (dateString != null) {
      if (showSeconds == null) {
        showSeconds = true;
      }
      // let now = moment().add(-1, 'days');
      let now = moment();
      let end = moment(dateString);

      // let duration = moment.duration(now.diff(end));
      let duration = moment.duration(end.diff(now));

      //Get Days and subtract from duration
      let days = duration.asDays();
      // duration.subtract(moment.duration(days, 'days'));
      // days = Math.abs(Math.floor(days));
      days = Math.abs(days);
      days = Number(days.toFixed(0));

      //Get hours and subtract from duration
      let hours = duration.hours();
      duration.subtract(moment.duration(hours, 'hours'));
      hours = Math.abs(hours);

      //Get Minutes and subtract from duration
      let minutes = duration.minutes();
      duration.subtract(moment.duration(minutes, 'minutes'));
      minutes = Math.abs(minutes);

      //Get seconds
      let seconds = duration.seconds();
      seconds = Math.abs(seconds);
      let resp = [];

      // SHOW DAYS
      if (days == 1) {
        resp.push(days + ' day');
      } else if (days > 1) {
        resp.push(days + ' days');
      } else {
        // SHOW HOURS
        if (hours == 1) {
          resp.push(hours + ' hour');
        } else if (hours > 1) {
          resp.push(hours + ' hours');
        }

        // SHOW MINS
        if (hours < 4) {
          if (minutes == 1) {
            resp.push(minutes + ' min');
          } else if (minutes > 1) {
            resp.push(minutes + ' mins');
          }
          else {
            // SHOW SECONDS
            if (seconds == 1 && showSeconds) {
              resp.push(seconds + ' second');
            } else if (seconds > 1 && showSeconds) {
              resp.push(seconds + ' seconds');
            }
          }

        }
      }
      return resp.join(', ');
    } else {
      return dateString;
    }
  }
}

@Pipe({ name: 'daysToNow' })
export class DaysToNow implements PipeTransform {
  transform(dateString: string, numberOnly?: boolean): string {
    if (dateString != null) {
      dateString = dateString.split('T')[0];

      let value = moment(dateString).add(1, 'days').diff(moment(), 'days');

      if (numberOnly) {
        return value.toString();
      }

      let resp = value + ' Days';

      if (value == 1) {
        resp = value + ' Day';
      } else if (value == 0) {
        resp = 'Today';

        // let diff = moment().utcOffset('-0500').diff(moment(dateString));
        // value= moment.utc(diff).format("HH:mm");
      }

      return resp;
    }

    // let date1 = new Date(dateString);
    // let date2 = Date.now();
    //
    // // var diff = Math.abs(date1.getTime() - date2.getTime());
    // let diff = date1.valueOf() - date2.valueOf();
    // let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    //
    // return diffDays.toLocaleString();
  }
}

// @Pipe({ name: 'dateToSign' })
// export class DateToSign implements PipeTransform {
//   transform(dateString: string): string {
//     let check = Date.parse(dateString);
//     let year = new Date(dateString).getFullYear();

//     let from = Date.parse('03/21/' + year);
//     let to = Date.parse('04/20/' + year);
//     if (check <= to && check >= from) {
//       return 'Aries';
//     }

//     from = Date.parse('04/21/' + year);
//     to = Date.parse('05/20/' + year);
//     if (check <= to && check >= from) {
//       return 'Taurus';
//     }

//     from = Date.parse('05/21/' + year);
//     to = Date.parse('06/20/' + year);
//     if (check <= to && check >= from) {
//       return 'Gemini';
//     }

//     from = Date.parse('06/21/' + year);
//     to = Date.parse('07/22/' + year);
//     if (check <= to && check >= from) {
//       return 'Cancer';
//     }

//     from = Date.parse('07/23/' + year);
//     to = Date.parse('08/22/' + year);
//     if (check <= to && check >= from) {
//       return 'Leo';
//     }

//     from = Date.parse('08/23/' + year);
//     to = Date.parse('09/22/' + year);
//     if (check <= to && check >= from) {
//       return 'Virgo';
//     }

//     from = Date.parse('09/23/' + year);
//     to = Date.parse('10/22/' + year);
//     if (check <= to && check >= from) {
//       return 'Libra';
//     }

//     from = Date.parse('10/23/' + year);
//     to = Date.parse('11/22/' + year);
//     if (check <= to && check >= from) {
//       return 'Scorpio';
//     }

//     from = Date.parse('11/23/' + year);
//     to = Date.parse('12/21/' + year);
//     if (check <= to && check >= from) {
//       return 'Sagittarius';
//     }

//     from = Date.parse('01/20/' + year);
//     to = Date.parse('02/20/' + year);
//     if (check <= to && check >= from) {
//       return 'Aquarius';
//     }

//     from = Date.parse('02/19/' + year);
//     to = Date.parse('03/20/' + year);
//     if (check <= to && check >= from) {
//       return 'Pisces';
//     }

//     // date spands over year change, maybe year+1 works for now being last return
//     // from = Date.parse('12/22/' + year);
//     // to   = Date.parse('1/19/' + year);
//     return 'Capricorn';
//   }
// }


@Pipe({ name: 'dateToDayofMonth' })
export class DateToDayofMonth implements PipeTransform {
  transform(dateString: string): string {
    let date1 = new Date(dateString);
    //add one day because expires end the day before at midnight
    date1.setDate(date1.getDate() + 1);
    let day = date1.getDate();
    let suffix = 'th';

    if (day == 1 || day == 21 || day == 31) {
      suffix = 'st';
    } else if (day == 2 || day == 22) {
      suffix = 'nd';
    } else if (day == 3 || day == 23) {
      suffix = 'rd';
    }
    return day.toString() + suffix;
  }
}

@Pipe({ name: 'dateToDayName' })
export class DateToDayName implements PipeTransform {
  transform(dateString: string): string {
    let date1 = new Date(dateString);
    //add one day because expires end the day before at midnight
    date1.setDate(date1.getDate());
    let day = date1.getDay();
    let weekday = new Array(7);
    weekday[0] = 'Sun';
    weekday[1] = 'Mon';
    weekday[2] = 'Tue';
    weekday[3] = 'Wed';
    weekday[4] = 'Thu';
    weekday[5] = 'Fri';
    weekday[6] = 'Sat';

    return weekday[day];
  }
}

@Pipe({ name: 'graphMinPercent' })
export class GraphMinPercent implements PipeTransform {
  transform(percent: number): string {
    if (percent < 0.5 && percent != 0) {
      return '3px';
    } else {
      return percent + '%';
    }
  }
}
