<mat-sidenav-container>
  <mat-sidenav fxLayout="column" #sidenav [opened]="flags.sidenav" (closedStart)="closeSideNav()" mode="over">
    <a8-sidenav></a8-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>

  <main [@routerTransition]="getRouterState(o)" (swiperight)="openSideNav()">
    <a8-header></a8-header>
    <alert></alert>
    <router-outlet #o="outlet"></router-outlet>
  </main>
  <a8-nav></a8-nav>

  </mat-sidenav-content>
</mat-sidenav-container>

<!-- <app-footer *ngIf="!user"></app-footer> -->
