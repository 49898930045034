import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Activity, User } from 'src/app/models';
import { UtilsService } from 'src/app/services';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
})
export class ExperienceComponent implements OnInit, OnDestroy {
  @Input() activity: Activity;
  @Output() change: EventEmitter<any> = new EventEmitter();

  user: User;
  subs: Subscription[] = [];
  experience: number = 3;
  flags = {
    btnDone: false,
    animation: 'start',
  }

  constructor(
    public utils: UtilsService,
    private activityService: ActivityService
  ) {

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
    this.subs.push(user);
  }

  ngOnInit() { 
    this.activateDone();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.activateDone();
  }

  activateDone(){
    if(this.activity != null && this.activity.experience != 0){
      this.flags.btnDone = true;
    }    
  }

  set(exp) {
    this.flags.btnDone = true;
    this.activity.experience = exp;
    this.utils.sound('next');
  }

  clickDone(){
    let fields = {};
    if(this.activity != null){
      fields['experience'] = this.activity.experience;
      fields['has_certification'] = false;
      fields['will_teach'] = false;

      if(this.activity.has_certification){
        fields['has_certification'] = true;
      }
      if(this.activity.will_teach){
        fields['will_teach'] = true;
      }
      this.flags.btnDone = false;

      this.activityService.update(this.activity.id, fields).subscribe((r) => { });
    }
    this.flags.animation = 'end';
    this.utils.delay(300).then(() => {
      this.flags.animation = 'start';
      this.user.app_state.showSetExperience = 'out';
      this.utils.appState.user.next(this.user);
      this.change.emit(fields);
    });


  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
