<div fxLayout="row" fxLayoutGap="20px">
  <div fxFlex class="location">
    <form [formGroup]="form" (ngSubmit)="clickAddLocation()">
      <button type="button" mat-flat-button color="primary" class="mapBtn" (click)="openMap()">
        <mat-icon>map</mat-icon>
      </button>
        <mat-form-field>
        <input placeholder="Enter a location" matInput #locationInput [formControl]="locationCtrl" [matAutocomplete]="auto" (focus)="locationCtrl.setValue('')" [value]="inputLocation?.location?.displayString">
      </mat-form-field>

      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
        <mat-option *ngFor="let location of filteredLocations | async" [value]="location">
          {{location.name}}<span *ngIf="location?.name">, </span>{{location?.place?.properties?.street}}<span *ngIf="location?.place?.properties?.street">, </span>{{location?.place?.properties?.stateCode}}
          </mat-option>
      </mat-autocomplete>
    </form>
    <br clear="all" />
  </div>
</div>

<div class="suggestions" *ngIf="suggestedLocations.length > 0">
  <h2 class="text-center">Suggestions</h2>
  <div fxLayout="row" fxLayoutAlign="center center" class="hcenter">
    <button *ngFor="let location of suggestedLocations" mat-button type="button" class="pill" (click)="clickPill(location)">
    {{ location.name }}
    </button>
  </div>
</div>

