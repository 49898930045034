import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { StripeService } from 'ngx-stripe';
import { UtilsService } from 'src/app/services';
import { BillingService } from 'src/app/services/billing.service';

interface iPlan {
  id: string;
}

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent implements OnInit {
  flags = {
    sfe: false, // Show Form Errors
    loading: false,
    btnSubmit: true,
    showPayment: false,
  };
  customer: any;
  plans: iPlan[];

  constructor(
    private router: Router,
    private stripeService: StripeService,
    private billingService: BillingService,
    public utils: UtilsService
  ) {
    // POST PAID
    if (this.router.url.includes('/plans/web')) {
      this.flags.showPayment = true;
    }
    this.plans = [];
  }

  ngOnInit(): void {
    // this.createPaymentForm();
    this.getStripeCustomer();
    this.getPlans();
  }

  startCheckout(plan: iPlan) {
    this.billingService.startCheckoutSession(plan.id).subscribe((resp) => {
    // this.billingService.startCheckoutSession('price_1O9dTFBkhxZ8Z7zg8zxbiDNi').subscribe((resp) => {
      const stripe: any = this.stripeService.getInstance();
      stripe.redirectToCheckout({ sessionId: resp['session_id'] }).then(function (result) {
          console.log('redirectToCheckout', result);
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
    });
  }

  // DATA
  getStripeCustomer() {
    this.billingService.getCustomer().subscribe((data) => {
      this.customer = data;
    });
  }

  getPlans() {
    this.billingService.listPlans().subscribe((data) => {
      this.plans = data;
    });
  }
}
