import * as _ from 'lodash';

export class Activity {
    id: number;
    slug: string;
    name: string;
    type: string;
    is_premium: boolean;
    has_certification: boolean;
    will_teach: boolean;
    description: string;
    url: string;
    images: any;
    tags?: any[];
    group: {name:string, slug: string};

    experience: number;
    is_interest: boolean;
    is_alias: boolean;
    days: string;
    activity?: Activity;
    price?: string;
    duration?: string;

    meta: any;

    constructor(data: Object) {
        Object.assign(this, data);
    }

    public static fromList(datalist: object[]) {
    return _.map(datalist, item => {
      return new Activity(item);
    });
  }
    
}
  