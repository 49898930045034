import { Activity } from "./activity";
import { User } from "./user";
import { GeoLocation } from "./location";
import * as _ from 'lodash';

export class Post {
  id: number;
  slug: string;
  activity?: Activity;
  name?: string;
  description?: string;
  url?: string;
  type?: string;
  pets?: boolean;
  family?: boolean;
  sober?: boolean;

  date?: string;
  time?: string;
  datetime?: string;
  last_comment: { message?: string, user?: User };
  accepted_invite_count?: number;
  like_count?: number;
  comment_count: number;
  comments: any;

  from?: User;
  creator?: User;
  location?: GeoLocation;
  locationDisplay?: string;
  citytag?: string;
  tags?: string[];
  duration?: string;
  confirmed?: number;
  size?: number;
  price?: string;
  img?: string;
  images?: any;
  imagePreload?: any;
  private?: boolean;
  request_guide?: boolean;
  published?: boolean;
  meta: any;
  conversation?: any;

  constructor(data?: Object) {
    if(data){
      Object.assign(this, data);
    }
    if (this.last_comment == null) {
      // this.last_comment = {};
    }
  }

  public static fromList(datalist: object[]) {
    return _.map(datalist, item => {
      return new Post(item);
    });
  }

  setLastMessage(message, user) {
    if (this.last_comment == null) {
      this.last_comment = {};
    }
    this.last_comment.message = message;
    this.last_comment.user = user;
  }

}

