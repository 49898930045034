import { animation } from '@angular/animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  Input,
  SimpleChanges,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Activity, User } from 'src/app/models';
import { UtilsService } from 'src/app/services';
import { ActivityService } from 'src/app/services/activity.service';
import { BillingService } from 'src/app/services/billing.service';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-activities',
  templateUrl: './add-activities.component.html',
  styleUrls: ['./add-activities.component.scss'],
})
export class AddActivitiesComponent implements OnInit, OnDestroy {
  @Output() done: EventEmitter<any>;
  @Input() mode: string;

  flags = {
    loading: false,
    btnAddingActivities: true,
    animation: 'start',
    popularCount: 19,
    strike: 0,
  };
  user: User;
  subs: Subscription[] = [];
  form: FormGroup;
  prices: any = ['Free', 5, 10, 20, 50, 'Verify to Unlock'];
  durations: any = [
    { name: '15 Mins', value: '00:10:00' },
    { name: '30 Mins', value: '00:30:00' },
    { name: '45 Mins', value: '00:45:00' },
    { name: '1 Hour', value: '01:00:00' },
    { name: '75 Mins', value: '01:15:00' },
    { name: '1.5 Hours', value: '01:30:00' },
    { name: '2 Hours', value: '02:00:00' },
    { name: '2.5 Hour', value: '02:30:00' },
    { name: '3 Hour', value: '03:00:00' },
    { name: '3.5 Hour', value: '03:30:00' },
    { name: '4 Hour', value: '04:00:00' },
    { name: '4.5 Hour', value: '04:30:00' },
    { name: '5+ Hours', value: '05:00:00' },
  ];
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  activityCtrl = new FormControl();
  filteredActivities: Observable<Activity[]>;
  activity: Activity;
  activities: Activity[] = [];
  previousActivities: Activity[] = [];
  previousInterests: Activity[] = [];
  allActivities: Activity[] = [];
  bannedActivities: Activity[] = [];
  popularActivities: Activity[] = [];
  placeholder: string = 'Enter activities here...';
  @ViewChild('activityInput') activityInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private activityService: ActivityService,
    public utils: UtilsService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private billingService: BillingService
  ) {
    this.done = new EventEmitter();
    
    this.utils.appState.bannedActivities.subscribe((resp) => {
      this.bannedActivities = resp;
    });
    
    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      this.splitActivitiesInterests();
      
      if(this.user.is_verified){
        this.prices = ['Free', 5, 8, 10, 15, 20, 25, 30, 35, 40, 42, 45, 50, 60, 70, 80, 88, 90, 100];
      }
      this.adjustPopularCount();      
    });
    this.subs.push(user);

    this.filteredActivities = this.activityCtrl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        (typeof value === 'string' ? value : value['name'])
      ),
      // map(name => name ? this._filter(name) : this.allActivities.slice()),
      map((name) => {
        if (name.length > 0) {
          return this._filter(name);
        }
      })
    );

    this.getPopularActivities();
  }

  ngOnInit() {
    if(this.bannedActivities.length == 0){
      this.getBandActivities();
    }

    this.form = this.formBuilder.group({
      activities: ['', [Validators.required]],
      // duration: ['00:00:20', Validators.required],
      // price: [this.roundPrice(20)],

    });

    this.setBtnAddingActivities();
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.mode = changes.mode.currentValue;
    if (this.mode != null) {
      this.splitActivitiesInterests();
    }
    if (this.mode == 'interest') {
      this.placeholder = 'Enter interests here...';
    }
    else {
      this.placeholder = 'Enter activities here...';
    }
  }

  // ADJUSTMENTS
  verify() {
    this.billingService
      .verifyID({ return_url: 'https://activity8.app/dashboard/verifing' })
      .subscribe((data) => {
        window.location = data.redirect_url;
      });
  }

  roundPrice(price){
    if(!isNaN(price)){
      return Math.floor(Number(price));
    }
    else{
      return 'Free';
    }
  }

  disableBtn(){
    if(this.activityCtrl.value == null || this.activityCtrl.value == '' || this.activityCtrl.value?.length <= 0){
      return true;
    }
    return false;
  }

  durationChange(value, activity){
    this.utils.sound('next');
    activity.duration = value;
    this.saveSettings(activity);
}

  priceChange(value, activity){
    this.utils.sound('next');
    if(value == 'Verify to Unlock'){
      if(this.user.verification_status == null || (this.user.verification_status != null && this.user.verification_status == 'Not Started')){
        this.verify();
      }
      else{
        this.utils.alert.message(this.user.verification_status);
      }
    }
    else{
      activity.price = value;
      this.saveSettings(activity);
    }

  }  

  setExperience(activity) {
    this.utils.sound();
    this.user.app_state.showSetExperience = 'in';
    this.activity = activity;
  }

  setDays(activity) {
    this.utils.sound();
    this.user.app_state.showSetDays = 'in';
    this.activity = activity;
  }

  clickActivity(activity, mode) {
      this.utils.sound('open');
  }
  // END ADJUSTMENTS

  splitActivitiesInterests() {
    this.previousInterests = _.filter(this.user.activities, (activity) => activity.is_interest);
    this.previousActivities = _.filter(this.user.activities, (activity) => !activity.is_interest);
    if (this.user.app_state.showSetActivities == 'interest') {
      this.activities = this.previousInterests;
    } else {
      this.activities = this.previousActivities;
    }
  }

  get f1() {
    return this.form.controls;
  }


  clickDone() {
    if (this.user.activities == null || this.user.activities.length == 0) {
      if(!confirm("Adding at least one activity is recommened.")){
        return;
      }
      this.user.app_state.showSetActivities = false;
      this.utils.appState.user.next(this.user);
    }

    this.flags.animation = 'end';
    this.utils.sound('next');
    if ((this.activityCtrl.value || '').trim()) {
      this.getActivity(this.activityCtrl.value.trim());
      this.setBtnAddingActivities();
    }

    if (this.user.app_state.showSetActivities == 'interest') {
      this.user.activities = _.orderBy(this.activities.concat(this.previousActivities), ['activity.name']);
    } else {
      this.user.activities = _.orderBy(this.activities.concat(this.previousInterests), ['activity.name']);
    }

    this.utils.delay(800).then(() => {
      this.user.app_state.showSetActivities = false;
      this.utils.appState.user.next(this.user);
      this.done.emit(false);
      this.flags.animation = 'start';
      });
  }

  clickAddActivities() {
    // FADE BACK
    // this.user.app_state['homeCover'] = 'in';
    // setTimeout(() => this.user.app_state['homeCover'] = 'in', 700);
    // this.utils.appState.user.next(this.user);
  }

  clickActivityPill(activity) {
    this.utils.sound();
    this.popularActivities = this.popularActivities.filter(
      (obj) => obj.id != activity.id
    );
    if (!this.isLinked(activity)) {
      this.linkActivity(this.user.id, activity);
    }
  }

  addFromButton(control) {
    this.utils.sound();
    if ((control.value || '').trim()) {
      this.getActivity(control.value.trim());
      this.setBtnAddingActivities();
    }
    control.value = '';
    this.activityInput.nativeElement.value = '';
    this.setBtnAddingActivities();
  }

  activityLimit() {
    if (this.activities.length >= 8) {
      return true;
    }
    else {
      return false;
    }
  }

  add(event: MatChipInputEvent): void {
    this.utils.sound();
    const input = event.input;
    const value = event.value;

    // Add activity
    if ((value || '').trim()) {
      this.getActivity(value.trim());

      this.setBtnAddingActivities();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.activityCtrl.setValue('');
  }

  remove(activity: Activity): void {
    this.activities = this.activities.filter((obj) => obj.id !== activity.id);
    this.unLinkActivity(activity.id);
    this.setBtnAddingActivities();
    this.adjustPopularCount();
  }

  adjustPopularCount(){
    if(this.activities.length > 5){
      this.flags.popularCount = 5;
    }
    else if(this.activities.length > 3){
      this.flags.popularCount = 7;
    }
    else if(this.activities.length > 2){
      this.flags.popularCount = 10;
    }
    else if(this.activities.length <= 2){
      this.flags.popularCount = 19;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.utils.sound();
    let activity = event.option.value;
    this.popularActivities = this.popularActivities.filter(
      (obj) => obj.id != activity.id
    );
    if (!this.isLinked(activity)) {
      this.linkActivity(this.user.id, activity);
    }
    this.activityInput.nativeElement.value = '';
    this.activityCtrl.setValue('');
    this.setBtnAddingActivities();
  }

  private _filter(name: string): Activity[] {
    const filterValue = this.capitalizeFirstLetter(name);

    this.getActivitiesByName(filterValue);

    return this.allActivities.filter((activity) => this.capitalizeFirstLetter(activity.name).indexOf(filterValue) === 0);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  displayFn(activity: Activity): string {
    return activity && activity.name ? activity.name : '';
  }

  setBtnAddingActivities() {
    if ((this.activities != null && this.activities.length > 0) || this.mode == 'interest') {
      this.flags.btnAddingActivities = false;
    } else {
      this.flags.btnAddingActivities = true;
    }
  }

  filterPopular() {
    if(!this.user.is_single){
      _.remove(this.popularActivities, (obj) => obj.is_dating);
    }
    this.previousInterests.forEach((activity) => {
      _.remove(this.popularActivities, (obj) => obj.id == activity.activity.id);
    });
    this.previousActivities.forEach((activity) => {
      _.remove(this.popularActivities, (obj) => obj.id == activity.activity.id);
    });
  }

  // FUNCTIONS
  isLinked(activity): boolean {
    let exists = this.activities.filter(
      (obj) => obj.activity.id === activity.id
    );
    if (exists.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  isSexual(name){
    const noActivity = [
    "Mj",
    "Fuck","Fucking","Screw","Shag","Bonk","Banging","Nail","Boink",
    "Blowjob","Oral","Cunnilingus","Fellatio","Head","Suck off","Deepthroat","Facefuck","Rimjob","Analingus",
    "Sex","Copulate","Intercourse","Penetrate","Masturbate","Ejaculate","Orgasm","Fondle","Stimulate","Seduce","Cuddle","Mounting","Bang",
    "Wank","Hump","Grope","Grind","Plunge","Fondle","Stroke","Lick","Thrust","Grind","Masturbate","Orgasm","Ejaculate","Undress","Seduce","Stimulate","Grope",
    "Caress","Fondle","Rub","Insert","Mount","Copulate","Intercourse","Penetrate","Fondle","Stroke","Lick","Thrust","Grind","Masturbate","Orgasm","Ejaculate",
    "Undress","Seduce","Stimulate","Grope","Caress","Fondle","Rub","Insert","Mount","Copulate","Intercourse","Tease","Moan","Gasp","Suck","Kiss","Hump","Cuddle",
    "Fondle","Groan","Pant","Strip","Snog","Spank","Smack","Tickle","Nibble","Provoke","Grasp","Tug","Clutch","Grope","Caress","Pet","Massage","Snuggle","Neck",
    "French","Wank","Bang","Pump","Mount","Spoon","Rub","Tease","Squeeze","Pinch","Probe","Smooch","Fiddle","Slap","Lap","Buck","Ball","Wriggle","Slide",
    "Nuzzle","Dive","Prod","Squirt","Squirm","Squelch","Sniff","Stroke","Mount","Probe","Pet","Fondle","Plunge","Tickle","Spank","Grope","Squeeze","Rub","Snuggle",
    "Massage","Smack","Grind","Caress","Embrace","Sniff","Nuzzle","Lick","Smooch","Tease","Suck","Pinch","Slap","Paw"];
    let dirty = false;
    dirty = noActivity.includes(name);
  
    let dblist = _.filter(this.bannedActivities, (activity) => activity.name == name);

    if(dirty || dblist.length > 0){
      this.flags.strike++;
      this.utils.alert.error('Strike '+ this.flags.strike +'! Keep it CLEAN!');
      return true;
    }
    return false;
  }  

  // DATA ITEMS
  saveSettings(userActivity) {
    this.activityService.update(userActivity.id, { price: userActivity.price, duration: userActivity.duration }).subscribe((r) => { });
  }

  getActivity(name) {
    if (name != null) {
      name = name.toLowerCase();
    }
    this.activityService.get(name).subscribe(
      (data) => {
        if (data != null) {
          this.linkActivity(this.user.id, data);
        } else if (data.detail != null && data.detail.toLowerCase() == 'not found.') {
          this.addActivity(name);
        }
      },
      (error) => {
        if (error.error.detail == 'Not found.') {
          this.addActivity(name);
        }
      }
    );
  }

  linkActivity(userid, activity) {
    if (this.isSexual(activity.name) || this.activityLimit()) {
      return;
    }

    let price = '20.00';
    let duration = '00:30:00';
    if(activity.price != null){
      price = activity.price;
    }
    if(activity.duration != null){
      duration = activity.duration;
    }

    let data: any = { user: userid, activity: activity.id, experience: 0, price: price, duration: duration };
    if (this.mode == 'interest') {
      data.is_interest = true;
    }
    this.activityService.link(data).subscribe((data) => {
      let useractivity = new Activity(data);
      useractivity.activity = activity;
      useractivity.experience = 0;
      this.activities.push(useractivity);

      this.user.activities.push(useractivity);
      this.adjustPopularCount();

      // this.utils.appState.user.next(this.user);
      this.filterPopular();

      this.setBtnAddingActivities();
      console.log(this.activities);
    });
  }

  unLinkActivity(activityid) {
    this.activityService.unlink(activityid).subscribe((data) => {
      // this.utils.appState.user.next(this.user);
      this.filterPopular();
    });
  }

  addActivity(name) {
    if (this.isSexual(name) || this.activityLimit()) {
      return;
    }

    this.activityService.create({ name: this.capitalizeFirstLetter(name),tags:'',is_alias:true }).subscribe((data) => {
      this.linkActivity(this.user.id, data);
    });
  }

  getActivitiesByName(name) {
    this.activityService.list({ search: name, limit: 10, is_alias:false }).subscribe((data) => {
      // NEED TO REMOVE USER ACTIVITIES
      // _.remove(data, function(activity) {
      //     return activity === this.user.activities;
      // });

      this.allActivities = Activity.fromList(data.results);
    });
  }

  getBandActivities() {
    this.activityService.list({ is_banned:true }).subscribe((data) => {
      this.bannedActivities = Activity.fromList(data.results);
      this.utils.appState.bannedActivities.next(this.bannedActivities);
    });
  }

  getPopularActivities() {
    this.activityService.list({ limit: 30, ordering: '-is_dating,-popular' }).subscribe((data) => {
      this.popularActivities = Activity.fromList(data.results);
      this.filterPopular();
    });
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
