import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { Component,ElementRef,EventEmitter,OnInit,Output,ViewChild,Input,SimpleChanges,OnDestroy,} from '@angular/core';
import {FormBuilder,FormControl,FormGroup,Validators,} from '@angular/forms';
import {MatAutocomplete,MatAutocompleteSelectedEvent,} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from 'src/app/models';
import { UtilsService, CurrentUserService, UserService } from 'src/app/services';
import { ActivityService } from 'src/app/services/activity.service';
import { LocationService } from 'src/app/services/location.service';
import { GeoLocation } from 'src/app/models/location';
import { Geolocation as CapLocate } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-location-place',
  templateUrl: './location-place.component.html',
  styleUrls: ['./location-place.component.scss'],
})
export class LocationPlaceComponent implements OnInit, OnDestroy {
  @Output() change: EventEmitter<GeoLocation>;
  @Input() inputLocation: any;
  @Input() nearby: any;
  @Input() mode: string;

  flags = {
    map: false,
    allowMap: true,
    viewMapOnly: false,
    setMap: false,
    settingActivity: false,
    loading: false,
    btnDoneDisabled: false,
    status: '',
    saveUserLocation: false,
    capLocation: false,
  };
  user: User;
  subs: Subscription[] = [];
  form: FormGroup;
  locationDisplay: string;
  location: GeoLocation;
  locationCtrl = new FormControl();
  filteredLocations: Observable<GeoLocation[]>;
  allLocations: GeoLocation[] = [];
  suggestedLocations: GeoLocation[] = [];
  
  mapLat: number = 51.678418;
  mapLong: number = 7.809007;
  
  @ViewChild('locationInput') locationInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private activityService: ActivityService,
    private meService: CurrentUserService,
    private userService: UserService,
    private locationService: LocationService,
    public utils: UtilsService,
    private formBuilder: FormBuilder
  ) {
    this.change = new EventEmitter();

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
    this.subs.push(user);

    this.filteredLocations = this.locationCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value['name'])),
      // // map(name => name ? this._filter(name) : this.allLocations.slice()),
      map((name) => {
        return this._filter(name);
      })
    ); 
  }

  ngOnInit() {
    this.initLocation();
    this.setupForm()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.inputLocation.currentValue != null){
      if (this.user.app_state.setActivityLocation != null) {
        this.location = this.user.app_state.setActivityLocation;
        this.locationDisplay = this.user.app_state.setActivityLocation.place;
        
        if(this.user.app_state.showSetLocation != null && !this.user.app_state.showSetLocation){
          this.user.app_state.showSetLocation = null;
          this.change.emit(this.location);
        }
      }
      else{
        this.initLocation();
      }
    }
  }

  setupForm() {
    this.form = this.formBuilder.group({
      locationDisplay: [this.locationDisplay],
      location: [this.location],
    });
  }

  initLocation(){
    //SET DEFAULT LOCATION AS AREA
    if (this.user.location != null && this.user.location.city != null) {
      this.locationDisplay = this.user.location.city + ', ' + this.user.location.state;
      this.location = this.user.location;
    }
    //SET DEFAULT LOCATION AS PLACE / BUSINESS
    else if (this.user.location != null && this.user.location.city != null && this.user.location.place == null) {
      this.location.place = this.locationDisplay;
    }

    if(this.nearby != null){
      this.getLocationSuggestions(this.user.location.location.coordinates, this.nearby);
    }
  }


  private _filter(name: string) {
    if (name.length > 1) {
      const filterValue = name.toLowerCase();

      if (this.user.app_state.showSetLocation != 'set-location' && this.user.location.location != null) {
        this.getLocationsByName(this.user.location.location.coordinates, filterValue);
      }
      else {
        this.getCitiesByName(filterValue);
      }

      // I NEED TO RETURN SUBSCRIPTION / PROMISE FROM ABOVE CALLS
      if (this.allLocations != null) {
        return this.allLocations.filter((location) => location.name.toLowerCase().indexOf(filterValue) >= 0);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let location: GeoLocation = event.option.value;
    this.clickPill(location);
  }  

  displayFn(activity: GeoLocation): string {
    return activity && activity.name ? activity.name : '';
  }

  clickAddLocation(){

  }

  clickPill(location: GeoLocation) {
    this.location.city = location.fields.city;
    this.location.state = location.fields.state;
    this.location.country = location.fields.country;
    
    if(location.place != null && location.place.properties != null){
      this.location.place = location.name;
      this.location.street = location.place.properties.street;
      this.location.city = location.place.properties.city;
      this.location.state = location.place.properties.stateCode;
      this.location.country = location.place.properties.countryCode;
    }
    else if(location.place != null && location.place.properties == null){
      this.location.city = location.city;
      this.location.state = location.state;
      this.location.country = location.country;
    }

    this.change.emit(this.location);
  }

  openMap() {
    this.utils.sound('next');
    this.user.app_state.showSetLocation = 'set-map';
    this.utils.appState.user.next(this.user);
  }


  

  // DATA ITEMS
  getCitiesByName(name) {
    this.locationService.getCities(name, 15).subscribe((data) => {
      this.allLocations = data.results;
    });
  }

  getLocationsByName(coordinates, searchStr) {
    this.locationService.getNearBy(coordinates, searchStr, 10).subscribe((data) => {
      this.allLocations = data;
    });
  }

  getLocationSuggestions(coordinates, searchStr?) {
    this.locationService.getNearBy(coordinates, searchStr, 30).subscribe((data) => {
      this.suggestedLocations = data;
    });
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
