export class Zodiac {
    from: string;
    to: string;
    name: string;
    colors: string;
    stone: string;
    days: string;
    symbol: string;
    numbers: string;
    planet: string;
    compatiable: string;
  
    constructor(data?: Object) {
        if(data){
            Object.assign(this, data);
        }
    }
}
  

  