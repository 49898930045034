<div class="authentic height100 vcenter">
    <button mat-flat-button class="closeBtn" routerLink="/dashboard" [class.iosPadding]="utils.ios()">
      <mat-icon>keyboard_arrow_left</mat-icon> Back
    </button>
    <br />
    <h1>Subscribe</h1>


    <!-- <ng-container *ngIf="!utils.ios() || flags.showPayment">
    </ng-container> -->
    <div class="premNotch" *ngIf="utils.ios()"></div>
    <img src="./assets/img/premium.png" class="premium" [class.ios]="utils.ios()" />

    <h3>GO Preeem!</h3>
    <h4>Only $9.99 per month!</h4>
    <ul >
      <li><mat-icon>verified</mat-icon> Premium Badge</li>
      <li><mat-icon>fingerprint</mat-icon> Verified Identity</li>
      <li><mat-icon>monetization_on</mat-icon> Unlock Prices</li>
      <li><mat-icon>favorite</mat-icon> IRL Advocate</li>
    </ul>
    <br />

    <button mat-button class="full height10 animate__animated animate__headShake" *ngFor="let plan of plans;" (click)="startCheckout(plan)">
      Activate Now <mat-icon>keyboard_arrow_right</mat-icon> 
    </button> 
    <!-- <img src="./assets/img/dancing.png" class="exciting" /> -->
    <!-- <button mat-button class="full" onclick="getElementById('buy').scrollIntoView();"  >6 Months! for $19.99</button> -->
    <!-- <mat-icon class="down">arrow_drop_right</mat-icon> -->
    <h4 class="text-center bottom">Start planning your next play Date now!</h4>

  
</div>


<!-- <div fxlayout="column" fxLayoutAlign="center center" class="backgroundCoverFull payment" id="buy">
</div> -->
