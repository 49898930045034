<mat-progress-bar mode="indeterminate" *ngIf="flags.loading" [class.iosProgress]="utils.ios()"></mat-progress-bar>
    <div class="items animate__animated animate__flipInY" *ngIf="id != null">
      <div class="item" [class.backColorLightGreen]="id?.status=='verified'"  [class.backColorOrange]="id?.status != null && id?.status!='verified'">
        <button mat-button class="closeBtn" (click)="closeClick()"><mat-icon>close</mat-icon></button>
        <div class="question">
          Identity {{ id?.status}} {{ id?.verification_date|date}}
          <mat-icon>face</mat-icon>
          <img [src]="id?.photo_info?.url" *ngIf="id?.photo_info?.url" />
          <br />
        </div>
    </div>
    <div class="item experience" [class.backColorLightGreen]="id?.status=='verified'"  [class.backColorOrange]="id?.status != null && id?.status!='verified'">
      <p>Full name: <b>{{ id?.verified_name}}</b></p>
      <p>Date of birth: <b>{{ id?.verified_dob}}</b></p>
      <br />
      
    </div>
  </div>