import { Component, ViewChild, NgZone } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { inOutAnimation, routerTransition } from './animations';
import { User } from './models/user';
import { AppStateService } from './services/app-state.service';
import { UtilsService } from './services/utils.service';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { App, StateChangeListener, URLOpenListenerEvent } from '@capacitor/app';
import { Location } from '@angular/common';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition, inOutAnimation],
})
export class AppComponent {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  title = 'a8';
  user: User;
  subs: Subscription[] = [];

  flags = {
    authenticatedMode: false,
    sidenav: false,
    onProfile: false,
  };
  items: Observable<any[]>;

  constructor(
    private appState: AppStateService,
    private utils: UtilsService,
    public router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private zone: NgZone,
  ) {
    this.initializeApp();


    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let urlParts = event.url.split('/');
        if (urlParts[1] == 'profile' && urlParts.length == 3) {
          this.flags.onProfile = true;
        }
      }
    });

    if (Capacitor.isNativePlatform()) {
      App.addListener('appStateChange', ({ isActive }) => {
        if(isActive){
          this.user.meta.update = true;
          this.utils.appState.user.next(this.user);
        }
      });

      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split(".app").pop();
            if (slug) {
                this.router.navigateByUrl(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
      });      
      App.addListener('backButton', data => {
        if(window.location.href != null && window.location.href.toLowerCase().includes('/dashboard')){
          App.exitApp();
        }
        else if(this.flags.onProfile){
          // CODE DOESN"T MAKE SENSE TO ME BUT WORKS GOOD
          this.router.navigateByUrl('/dashboard', {skipLocationChange: true});
          this.location.back();
          this.flags.onProfile = false;
        }
        else{
          this.location.back();
        }
     });

      Network.addListener('networkStatusChange', status => {
        console.log('Network status changed', status);
      });
      
      const logCurrentNetworkStatus = async () => {
        const status = await Network.getStatus();
        if(status != null && !status.connected){
          this.utils.alert.error('Network Offline');
        }
        console.log('Network status:', status);
      };     
    }
  }

  initializeApp() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-161580073-1', {
          page_path: event.urlAfterRedirects,
        });
      }
    });

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      // NOT SURE HOW TO SOLVE THE IS AUTH ON PAGE REFRESH
      // this.me.isAuth();
      // this.flags.authenticatedMode = resp.isAuthenticated;
    });
    this.subs.push(user);

    const sidenav = this.appState.sidenav.subscribe((resp) => {
      this.flags.sidenav = resp;
    });
    this.subs.push(sidenav);
  }

  closeSideNav(): void {
    this.sidenav.close();
    this.flags.sidenav = false;
    this.utils.appState.sidenav.next(false);
  }

  openSideNav(): void {
    let back = false;
    if (
      this.router.url.includes('/join') || this.router.url.includes('/messages/')
      // || (this.user != null && this.router.url.includes('/') && this.router.url != '/' + this.user.username)
      || (this.router.url.includes('/') && this.router.url.split('/').length > 3 && this.user != null)
      ){
      back = true;
    }


    if(!this.flags.sidenav && !back && this.user != null && this.user.app_state.showSetLocation == null && !this.router.url.includes('/')){
      this.flags.sidenav = true;
      this.utils.appState.sidenav.next(true);
    }

    if (back){
      this.location.back();
    }
  }

  getRouterState(outlet) {
    return outlet.activatedRouteData.state;
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
