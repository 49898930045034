import { Activity } from "./activity";
import { User } from ".";
import * as _ from 'lodash';
import { Post } from "./post";
import { GeoLocation } from 'src/app/models/location';

export class Invite {
    id: number;
    activity: Activity;
    datetime: string;
    created: string;
    date: string;
    time: string;
    ifrom: User;
    to: User;
    location?: GeoLocation;
    message?: string;
    comment?: string;
    response_comment?: string;
    review_comment?: string;
    duration: string;
    price: string;
    status?: string;
    from_attended?: boolean;
    to_attended?: boolean;
    post?: Post;

    constructor(data: Object) {
        Object.assign(this, data);
      }

    public static fromList(datalist: object[]) {
      return _.map(datalist, item => {
        return new Invite(item);
      });
    }      
}
  