import { Component, Input, OnInit, SimpleChanges, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { BackgroundAnimations, inOutAnimation } from 'src/app/animations';
import { User } from 'src/app/models';
import { Post } from 'src/app/models/post';
import { UtilsService, UserService } from 'src/app/services';
import * as moment from 'moment';

@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss'],
  animations: [BackgroundAnimations, inOutAnimation],
})
export class SearchUsersComponent implements OnInit, OnDestroy {
  @Input() searchStr;
  @Input() slug;
  @Output() resultCount: EventEmitter<Number> = new EventEmitter();
  flags = {
    sfe: false, // Show Form Errors
    loading: false,
    started: false,
    btnSubmit: true,
    noResults: false,
    ordering: null,
  };

  loadLength: number = 30;
  resultOffset: number = 0;
  resultTotal: number = 0;

  users: User[] = [];
  user: User;
  subs: Subscription[] = [];

  constructor(
    private userService: UserService,
    public utils: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(
      (params) => {
        if(this.slug == null){
          this.searchStr = params['searchStr']
        }
      }
    );

  }

  ngOnInit() { 
    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      this.flags.ordering = this.setOrdering();
      if(_.isEmpty(this.searchStr)){
        this.startSearch();
      }
    });
    this.subs.push(user);
    if(this.user == null){
      this.startSearch();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!_.isEmpty(this.searchStr) ){
      this.startSearch();
    }
  }

  swipeLeft(){
    this.router.navigateByUrl('/search');
  
  }

  nth(i, size){
    return i % size === 0;
  }

  startSearch(){
    this.users = null;
    this.users = [];
    this.resultOffset = 0;
    this.resultTotal = 0;
    this.getUsers();
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    if(this.slug == null){
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight / 1.3) {
        if (!this.flags.loading && !this.flags.noResults && _.isEmpty(this.searchStr)) {
          this.loadMore();
        }
      }
    }
  };

  loadMore() {
    this.resultOffset += this.loadLength;
    this.getUsers();
  }


  setOrdering(){
    if(this.user.app_state.search_date_joined){
      return '-date_joined';
    }
    else if(this.user.app_state.search_last_activity){
      // return '-last_login';
      return '-last_activity';
    }
  }

  
  //DATA
  getUsers() {
    this.flags.loading = true;
    let filters: any = {};
    filters.expand = 'profile_pics';
    // filters.fields = 'color,full_name,membership,thumbnail,username';
    filters.offset = this.resultOffset;
    filters.limit = this.loadLength;
    // filters.order_by_pic = 'ASCENDING';
    // filters.order_by_pic = 'DESCENDING';

    filters.ordering = 'profile_pics';
    if(this.flags.ordering){
      filters.ordering = this.flags.ordering;
    }

    if(this.user != null){
      if (this.user.app_state.search_location) {
        filters.location_lat = this.user.location.location.coordinates[0];
        filters.location_lng = this.user.location.location.coordinates[1];
        filters.location_radius = 100;
      }

      if(this.user.app_state != null && this.user.app_state.search_gender){
        filters.gender = this.user.app_state.search_gender;
      }
      if(this.user.app_state != null && this.user.app_state.search_is_straight != null){
        filters.orientation =  this.user.app_state.search_is_straight === true ? 'S' : this.user.app_state.search_is_straight === false ? 'G' : null;
      }
      if(this.user.app_state != null && this.user.app_state.search_is_single != null){
        filters.is_single =  this.user.app_state.search_is_single;
      }
      if(this.user.app_state != null && this.user.app_state.search_is_drinker != null){
        filters.is_drinker =  this.user.app_state.search_is_drinker;
      }
      if(this.user.app_state != null && this.user.app_state.search_is_smoker != null){
        filters.is_single =  this.user.app_state.search_is_smoker;
      }

      if (this.user.app_state.search_myactivities) {
        let activityids = this.user.activities.map((activity) => activity['activity'].slug);
        let activitybaseids = this.user.activities.filter((activity) => { 
          if(activity['activity'].group != null){
            return activity['activity'].group.slug;
          }
        }).map((activity) => activity['activity'].group.slug);
            activityids = activityids.concat(activitybaseids);
        let flatactivityids = activityids.join(',');
    
        filters.activities = flatactivityids;
      }      

    }

    if (this.slug != null) {
      filters.activities = this.slug;
    }
    else if (this.searchStr != null) {
      filters.search = this.searchStr;
    }

    this.userService.getAll(filters).subscribe((data) => {
      this.resultTotal = data.count;
      let users = User.fromList(data.results);

      _.each(users, (user) => {
        user.meta = {};
        let randomTiming = Math.floor(Math.random() * 9);
        user.meta = {};
        user.meta['timing'] = randomTiming;
        user.meta['imageNumber'] = 0;

        const date = moment().add(-30, 'days');
        if (date.isBefore(user.date_joined)) {
          user.meta.is_new = true;
        }
        
        if(this.user != null && this.user.zodaic != null && user.dob != null){
          user.zodaic = this.utils.zodiac(user.dob);
          user.meta.zodiacMatch = false;
          if(this.user.zodaic.compatiable.includes(user.zodaic.name)){
            user.meta.zodiacMatch = true;
          }
        }
      });

      if(this.user != null && this.router.url.includes('/members')){
        _.remove(users, (o) => o.id == this.user.id);
      }

      if (data.results.length == 0) {
        this.flags.noResults = true;
      }

      if (!_.isEmpty(this.searchStr)) {
        this.users = users;
      }
      else {
        this.users = this.users.concat(users);
      }

      this.resultCount.emit(this.users.length);
      this.flags.loading = false;
    });
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
