import { Component,ElementRef,OnInit,ViewChild,Input,OnDestroy, SimpleChanges, Output, EventEmitter,} from '@angular/core';
import {MatAutocomplete,} from '@angular/material/autocomplete';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';
import { GeoLocation } from 'src/app/models/location';
import { UtilsService } from 'src/app/services';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  @Input() inputLocation: GeoLocation;
  @Output() closed: EventEmitter<any>;;

  flags = {
    map: false,
    loading: false,
  };
  user: User;
  subs: Subscription[] = [];
  
  mapLat: number = 51.678418;
  mapLong: number = 7.809007;
  

  constructor(
    public utils: UtilsService,
  ) {
    this.closed = new EventEmitter();

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.inputLocation = changes.inputLocation.currentValue;

    if(this.inputLocation != null){
      this.mapLat = this.inputLocation.location.coordinates[1];
      this.mapLong = this.inputLocation.location.coordinates[0];
    }
  }

  mapClick($event){

  }


  close() {
    this.inputLocation = null;
    this.closed.emit(true);
    
  }



  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
