import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from '../../models';
import { UtilsService } from '../../services';
import { NotificationService } from '../../services/notification.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'a8-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // animations: [inOutAnimation]
})
export class HeaderComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  url: string;
  previousUrl: string;
  profileid: string;
  flags = {
    sidenav: false,
    onProfile: false,
    animate_icon: false,
    showDownload: true,
  };
  version = environment.VERSION;
  user: User = null;
  notificationsLimit: number = 100;
  currentPortfolio: any;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  openMenu() {
    this.trigger.openMenu();
  }

  constructor(
    public utils: UtilsService,
    private notificationService: NotificationService,
    public router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(
      (params) => (this.profileid = params['username'])
    );

    this.utils.appState.sidenav.subscribe((resp) => {
      this.flags.sidenav = resp;
      this.toggleAnimate();
    });

    // NOT updated before view loads?
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.url;
        this.url = event.url;

        this.flags.onProfile = false;
        let urlParts = this.url.split('/');
        if (urlParts[1] == 'profile' && urlParts.length == 3) {
          this.flags.onProfile = true;
        }
      }
    });

    this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
  }

  ngOnInit() { 
  }
  
  mobileWeb(){
    if(!Capacitor.isNativePlatform() && navigator.userAgent.match(/Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i)){
      return true;
    }
  }

  isPWA(){
    if (navigator.userAgent.match(/Android/i) && window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    } 
  }

  isPostDetail(){
    if(this.router.url.includes('/') && this.router.url.split('/').length > 3){
      return true;
    }
  }

  toggleAnimate(){
    // this.flags.animate_icon = !this.flags.animate_icon;
  }

  clickLogin() {
    this.router.navigateByUrl('/login?returnUrl=' + this.router.url);
  }

  closeDownload(){
    this.flags.showDownload = false;
  }

  showHome(){
    if(this.previousUrl != null && this.router.url != '/dashboard' && this.previousUrl.includes('returnUrl') 
    || this.previousUrl != null && this.flags?.onProfile && !this.previousUrl.includes('/dashboard/invites')){
      return true;
    }
    else{
      return false;
    }
  }

  logout() {
    if (confirm('Logout?')) {
      this.router.navigate(['/logout']);
    }
  }

  back() {
    let urlParts = this.url.split('/');
    if (urlParts != null && urlParts[1] == 'profile' && urlParts[2] == null) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.location.back();
    }
    // this.location.replaceState(this.url);
    // this.location.replaceState(this.previousUrl);
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }

}
