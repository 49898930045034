import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilsService } from './utils.service';
import { PaginatedList } from '../models';
import * as rxOp from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({ providedIn: 'root' })
export class BillingService {
  constructor(private http: HttpClient, private utils: UtilsService) {}

  listPlans(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:billing-plans');
    return this.http.get(url, this.utils.requestOptions(qp));
  }

  getCustomer(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:billing-customer');
    return this.http.get(url, this.utils.requestOptions(qp));
  }

  getSubscription(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:billing-customer');
    return this.http.get(url, this.utils.requestOptions(qp));
  }

  startCheckoutSession(planId: string) {
    const url = this.utils.getUrl('rest:billing-start-checkout');
    return this.http.post(url, { price_id: planId });
  }

  cancelSubscription(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:billing-cancel-subscription');
    return this.http.post(url, this.utils.requestOptions(qp));
  }

  verifyID(obj?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:billing-identity-verification');
    return this.http.post(url, obj);
  }

  getBalance(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:wallet-balance');
    return this.http.get(url, this.utils.requestOptions(qp));
  }

  claimBalance(obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:wallet-claim-money');
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  addBankAccount(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:billing-add-bank-account');
    return this.http.get(url, this.utils.requestOptions(qp));
  }

  addBankRefreshUrl(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:billing-bank-account-refresh-url');
    return this.http.get(url, this.utils.requestOptions(qp));
  }

  getTransactions(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:wallet-transactions-list');
    return this.http
    .get<PaginatedList>(url, this.utils.requestOptions(qp))
    .pipe(
      rxOp.map((resp: PaginatedList) => {
        return resp.results;
      })
    );    
  }

  payInvite(id: number, obj:Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:invites-checkout', id);
    console.log(url);
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  setReferralCode(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:referrals-default-code');
    return this.http.get(url, this.utils.requestOptions(qp));
  }

  // delete(id: string, qp?: Object): Observable<any> {
  //   const url = this.utils.getUrl('rest:post-detail', id);
  //   return this.http.delete(url, this.utils.requestOptions(qp));
  // }

  // create(obj: Object, qp?: Object): Observable<any> {
  //   const url = this.utils.getUrl('rest:post-list');
  //   return this.http
  //     .post(url, obj, this.utils.requestOptions(qp));
  // }

  // update(id: number, obj: Object, qp?: Object): Observable<any> {
  //   const url = this.utils.getUrl('rest:post-detail', id);
  //   return this.http
  //     .patch(url, obj, this.utils.requestOptions(qp));
  // }

  // ERROR LOGGING
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a StockService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }
}
