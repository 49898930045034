<mat-progress-bar mode="indeterminate" *ngIf="flags.loading" [class.iosProgress]="utils.ios()"></mat-progress-bar>
<ng-container *ngIf="users">
  <div *ngIf="users.length != 0">
    <div class="resultsHeader" [class.on]="searchStr != null"><mat-icon>face</mat-icon> Members</div>
  </div>

  <div class="squaresContainer" (swipeleft)="swipeLeft()">
    <!-- [href]="'https://activity8.app/'+ user?.username|safe" -->
    <a class="backgroundSquares "
        *ngFor="let user of users; let i = index;"
        [style.background-image]="'url(' + user?.thumbnail?.sm + ')'" 
        [@zoomBack]="user?.app_state?.homeBackground" [routerLink]="'/' + user?.username" 
        [style.animation-delay]="'0.' +user?.meta?.timing + 's'" [style.background-color]="user?.color + 'c7'" (click)="utils.sound('click-face')" [class.one]="nth(i,3)" [class.three]="nth(i+1,3)">
      <div fxlayout="row">

        <div class="title">
          <!-- {{ post.datetime|date:'E' }} @ {{ post.datetime|date:'shortTime' }} -->
          <span *ngIf="user?.full_name != ''">{{user?.full_name}}</span>
          <span *ngIf="user?.full_name == ''">Mystery</span>

        </div>
        <mat-icon class="premium zodaic" *ngIf="user?.meta?.zodiacMatch">hotel_class</mat-icon>
        <mat-icon class="premium" *ngIf="user?.is_premium && !user?.is_verified" [style.color]="user?.color">star</mat-icon>
        <mat-icon class="premium" *ngIf="!user?.is_premium && user?.is_verified" [style.color]="user?.color">fingerprint</mat-icon>
        <mat-icon class="premium" *ngIf="user?.is_premium && user?.is_verified" [style.color]="user?.color">verified</mat-icon>
        <mat-icon *ngIf="user?.meta?.is_new && !user?.is_verified && !user?.is_premium" class="premium" [style.color]="user?.color">fiber_new</mat-icon>

        <div class="noImage" *ngIf="!user?.thumbnail?.sm && user?.full_name != ''">{{user?.first_name|slice:0:3}}</div>
        <div class="noImage" *ngIf="!user?.thumbnail?.sm && user?.full_name == ''">:(</div>
        
      </div>
    </a>
  </div>

  <div class="noResults" *ngIf="searchStr == null && flags.noResults && !flags.loading">
    <h2><mat-icon>face</mat-icon></h2>
    <h5>No more users found</h5>
    <br /><br />
    <button mat-button routerLink="/members" onclick="window.scrollTo(0,0)" *ngIf="searchStr" (click)="searchStr=null;getUsers()"><mat-icon>arrow_back</mat-icon> Back</button>
    <br /><br />
  </div>
</ng-container>

