<div [class.ios]="utils.ios()" class="sidenav" (swipeleft)="closeSidenav()">

  <a (click)="closeSidenav()" class="close"><mat-icon>close</mat-icon></a>
  <ng-container *ngIf="!user?.isAuthenticated">
    <h1>options</h1>
    <a mat-button (click)="clickLogin();utils.sound('yes')">Join <mat-icon>chevron_right</mat-icon></a>
    <a mat-button (click)="clickLogin();utils.sound('click-face')">Login <mat-icon>chevron_right</mat-icon></a>
    <a routerLink="/about" mat-button (click)="closeSidenav();utils.sound()">Start <mat-icon>play_circle_outline</mat-icon></a>
    <a mat-button (click)="closeSidenav();utils.sound();openExternal('https://www.activity8.com')">Website<mat-icon>web</mat-icon></a>
    <a mat-button (click)="closeSidenav();utils.sound();openExternal('https://www.activity8.com/contact')">Contact <mat-icon>campaign</mat-icon></a>
    <br />
    <div class="download">
      <mat-icon>face</mat-icon>
      <h2>hello gorgeous</h2>
    </div>
  </ng-container>

  <ng-container *ngIf="user && user.isAuthenticated">
    <h1 (click)="closeSidenav();utils.sound('click-face')">
      <span *ngIf="user?.first_name != ''">{{ user?.first_name }}</span>
      <span *ngIf="user?.first_name == ''">ur name?</span>
    </h1>
    <div class="username" routerLink="/{{user?.username}}" (click)="closeSidenav();utils.sound('click-face')">@{{user?.username}}</div>

    <div class="profile">
      <div class="border" [style.border-color]="user.color">
        <div class="noImage" routerLink="/{{user?.username}}" (click)="closeSidenav();utils.sound('click-face')" *ngIf="getProfileBackground(user) == 'none'" [style.border-color]="user?.color"  [style.color]="user?.color + '80'">
          <span *ngIf="user?.first_name!=''">{{user?.first_name}}</span>
          <span *ngIf="user?.first_name==''">:(</span>
        </div>
        <button routerLink="/{{user?.username}}" (click)="closeSidenav();utils.sound('click-face')" [style.background-image]="flags.userImg">
        <mat-icon matTooltip="Premium Member" class="premium" *ngIf="user?.is_premium && !user?.is_verified" [style.color]="user?.color">star</mat-icon>
        <mat-icon matTooltip="Verified Member" class="premium" *ngIf="!user?.is_premium && user?.is_verified" [style.color]="user?.color">fingerprint</mat-icon>
        <mat-icon matTooltip="Verified & Premium" class="premium" *ngIf="user?.is_premium && user?.is_verified" [style.color]="user?.color">verified</mat-icon>
        <mat-icon *ngIf="user?.meta?.is_new && !user?.is_verified && !user?.is_premium" class="premium" [style.color]="user?.color">fiber_new</mat-icon>
          <!-- <div class="counter" [class.lightMode]="user?.color|isLight" [style.background-color]="user?.color">{{user?.activities?.length}}</div> -->
        </button>
      </div>
    </div>


    <div class="balance">
      <p [style.color]="user.color">Earned</p>
      <h2><button mat-button routerLink="/settings/transactions" (click)="closeSidenav();utils.sound()">{{balanceText}}</button></h2>
      <a (click)="openExternal('https://www.activity8.com/journal/earn')" *ngIf="balance == 0 && user.referral_code != null">learn to earn</a>
      <a routerLink="/settings/transactions" (click)="closeSidenav();utils.sound()" *ngIf="user.referral_code == null && balance == 0">set referral link to earn</a>
      <a (click)="claim()" *ngIf="balance != 0">claim your earnings now</a>
    </div>



    <a routerLink="/admin" mat-button (click)="closeSidenav();utils.sound()" *ngIf="user?.hasPerm('admin.add_logentry')">Admin <mat-icon>admin_panel_settings</mat-icon></a>
    <a routerLink="/{{user.username}}" mat-button (click)="closeSidenav(); utils.sound('click-face')">Profile <mat-icon>face</mat-icon></a>
    <a routerLink="/settings" mat-button (click)="closeSidenav();utils.sound()">Settings <mat-icon>settings</mat-icon></a>
    <a mat-button (click)="closeSidenav();utils.sound();openExternal('https://www.activity8.com/help')">How to... <mat-icon>help</mat-icon></a>
    <a mat-button (click)="closeSidenav();utils.sound();openExternal('https://www.activity8.com/giveaway')">Giveaways <mat-icon>volunteer_activism</mat-icon></a>
    <a routerLink="/contact" mat-button (click)="closeSidenav();utils.sound()">Feedback <mat-icon>campaign</mat-icon></a>
    <a routerLink="/logout" mat-button (click)="closeSidenav();utils.sound()">Logout <mat-icon>logout</mat-icon></a>
  </ng-container>


  <div class="footer">
    <a routerLink="/privacy" (click)="closeSidenav();utils.sound()">Privacy Policy</a>
    <a routerLink="/terms" (click)="closeSidenav();utils.sound()">Terms of Service</a><br />
    &copy; copyright activity8 {{ today|date:'yyyy' }}
  </div>
</div>
